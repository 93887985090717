import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'TableScraping',

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
    ],
    
    components: {
        AlertError,
    },

    data() {
        return this.initializeState();
    },

    async created() {
        await this.fetchData();
    },

    computed: {
        ...mapGetters({
            scrapers: 'liveScraper/scrapers',
            error: 'liveScraper/error',
            humanError: 'liveScraper/humanError',
            scrapersLoading: 'liveScraper/scrapersLoading',
            selectedPlayer: 'livePlayers/selectedPlayer',
            scrapersHistory: 'liveScraper/scrapersHistory',
            scrapersHistoryLoading: 'liveScraper/scrapersHistoryLoading',
        }),

        optionsComputed: {
            get: function () {
              return this.options;
            },
            set: function (newValue) {
              if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
              }
            }
        },

        showFooter() {
            return this.scrapersHistory.length > 0;
        }
    },

    watch: {
        // watches this.options object
        // triggers everytime this.options has changed
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },

        page: function () {
            this.fetchData();
        },

        itemsPerPage: function () {
            this.page = 1;
            this.fetchData();
        },

        scrapersHistory: function () {
            this.showNextButton = this.scrapersHistory.length === this.itemsPerPage;
            this.showPreviousButton = this.page > 1;
        }
    },

    methods: {
        initializeState() {
            return {
                dataTable: {
                    headers: [
                        { text: 'Data', value: 'created_at', sortable: false },
                        { text: 'Estado', value: 'status', sortable: false },
                        { text: 'Ação', value: 'action', sortable: false },
                    ],
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                    status: [
                        { text: 'Concluído', value: 'SUCCESS', icon: 'mdi-check-circle', color: 'success' },
                        { text: 'Falhou', value: 'FAILED', icon: 'mdi-alert-outline', color: 'warning' },
                        { text: 'Em progresso', value: 'IN_PROGRESS', icon: 'mdi-clock-time-four-outline', color: 'info' },
                    ],
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false,
                },
                itemsPerPageArray: [4, 8, 12],
                page: 1,
                itemsPerPage: 4,
                loadingScraper: false,
                showNextButton: true,
                showPreviousButton: false,
            };
        },

        getIconStatus(item){
            let output = null;
            this.dataTable.status.forEach((status) => {
                if (status.value === item.status) {
                    output = status.icon;
                }
            });
            return output;
        },

        getNameStatus(item){
            let output = null;
            this.dataTable.status.forEach((status) => {
                if (status.value === item.status) {
                    output = status.text;
                }
            });
            return output;
        },

        getColorIconStatus(item){
            let output = null;
            this.dataTable.status.forEach((status) => {
                if (status.value === item.status) {
                    output = status.color;
                }
            });
            return output;
        },

        async fetchPlayerWithHendonmobLink(){
            let payload = {}
            if(this.selectedPlayer && this.selectedPlayer.external_link){
                payload.url = this.selectedPlayer.external_link;
            }

            let result = await this.$store.dispatch('liveScraper/fetchPlayerWithHendonmobLink', payload);
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Jogador atualizado com sucesso.',
                    color: 'success'
                });
            }
            await this.fetchData();
            
            if(this.$route.params.id){
                await this.$store.dispatch('livePlayers/fetchSelectedPlayer', this.$route.params.id);
            }
        },

        async fetchData() {
            const player_id = this.selectedPlayer && this.selectedPlayer.hendonmob_id ? this.selectedPlayer.hendonmob_id : null;
            // Base payload
            let payload = {
                params: {
                    id: player_id,
                    offset: (this.page - 1) * this.itemsPerPage,
                    per_page: this.itemsPerPage,
                    player_id: player_id,
                }
            };

            // Add sortDesc parameter to payload.params
            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

            // API Call
            await this.$store.dispatch('liveScraper/fetchScraperByUserId', payload);
        },

        nextPage () {
            if (this.scrapersHistory.length === this.itemsPerPage) this.page += 1
        },
        
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },

        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },

        setActiveItem(item) {
            this.$store.commit('liveScraper/setSelectedScraper', item);
        },
    },
};