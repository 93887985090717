import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import Hero from '@/components/Hero/Hero.vue';
import TableLiveTournaments from '@/components/Tables/TableLiveTournaments/TableLiveTournaments.vue';
import DialogAddEditEventLive from '@/components/Dialog/DialogAddEditEventLive/DialogAddEditEventLive.vue';

export default {
    name: 'LiveEvents-Single',

    components: {
        Hero,
        TableLiveTournaments,
        DialogAddEditEventLive,
    },

    mixins: [
        UserMixin,
        AuthMixin,
        DataMixin,
    ],

    async created() {
        // Load necessary page data
        if (this.$route.params.id) {
            await this.fetchData();
        }
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            events: 'liveEvents/events',
            selectedEvent: 'liveEvents/selectedEvent',
            error: 'liveEvents/error',
            humanError: 'liveEvents/humanError',
        }),

        event: function() {
            let eventFormatted = this.selectedEvent;
            if(eventFormatted && eventFormatted.event){
                eventFormatted = eventFormatted.event;
            }
            return eventFormatted;
        }
    },

    methods: {
        initialState() {
            return {
                dialogues: {
                    editEventLive: false,
                }
            };
        },

        async fetchData() {
            let payload = {
                id: this.$route.params.id,
            }
         await this.$store.dispatch('liveEvents/fetchEventById', payload);
        },

        setActiveItem(item) {
            if (item) {
              this.$store.commit(
                'liveEvents/setSelectedEvent',
                item
              );
            }
          },

        dialogEditEventLive(){
            this.setActiveItem(this.event);
            this.dialogues.editEventLive = true;
        },
    }
}