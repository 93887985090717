var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogAddEditTournamentLive',{attrs:{"is-open":_vm.dialogues.addEditTournament,"action":_vm.action},on:{"close-dialog":function($event){return _vm.closeDialogAddEditTournament()},"reload":function($event){return _vm.reloadTournaments()}}}),_c('div',[_c('DialogConfirmation',{ref:"DialogConfirmation",attrs:{"is-open":_vm.dialogues.removeTournament,"title":"Apagar torneio","body":"Tem a certeza que quer apagar este torneio?","width":376,"button-name":"Apagar","button-color":"error","submitting":_vm.dialogues.deleteSubmitting,"error":_vm.error,"human-error":_vm.humanError},on:{"close-dialog":function($event){return _vm.closeDialogRemoveConfirmation()},"submit":function($event){return _vm.deleteTournament()}}})],1),_c('v-card-title',[_vm._v(" Lista de torneios live "),_c('v-spacer'),(_vm.userIsRailManager)?_c('div',[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent","depressed":""},on:{"click":function($event){return _vm.openDialogAddEditTournament('create')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Adicionar torneio ")],1)],1):_vm._e()],1),_c('v-data-table',{ref:"liveListTable",staticClass:"table-scroll",attrs:{"height":_vm.$vuetify.breakpoint.smAndUp ? _vm.calcHeight() : _vm.calcHeightMobile(),"headers":_vm.headers,"items":_vm.allTournaments,"search":_vm.search,"footer-props":_vm.footerProps,"loading":_vm.tournamentsLoading,"loading-text":"A carregar...","must-sort":"","hide-default-footer":"","server-items-length":_vm.options.itemsPerPage},on:{"click:row":_vm.setActiveItem},scopedSlots:_vm._u([{key:"item.event.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%","text-align":"left","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},on),[(item.event)?_c('button',{staticStyle:{"width":"100%","text-align":"left","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},on:{"click":function($event){return _vm.dialogViewEvent(item)}}},[_vm._v(" "+_vm._s(item.event.name)+" ")]):_c('span',[_vm._v(" sem evento vinculado ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.event ? item.event.name : 'sem evento vinculado'))])])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticStyle:{"width":"100%","text-align":"left","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},on:{"click":function($event){return _vm.dialogViewTournament(item)}}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$moment(item.starts_at).format('DD/MM/YYYY HH:ss')))])]}},{key:"item.late_registration_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$moment(item.late_registration_at).format('DD/MM/YYYY HH:ss')))])]}},{key:"item.buyin",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.buyin,"currency":null,"precision":2,"allow-negative":"","disabled":""}})]}},{key:"item.prize_pool",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.prize_pool,"currency":null,"precision":2,"allow-negative":"","disabled":""}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogViewTournament(item)}}},on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Ver torneio")])]),(_vm.userIsAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""},on:{"click":function($event){return _vm.openDialogAddEditTournament('update', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar torneio")])]):_vm._e(),(_vm.userIsAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"dense":""},on:{"click":function($event){return _vm.openDialogRemoveConfirmation(item.id)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Apagar torneio")])]):_vm._e()],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.tournamentsLoading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-trophy-award","class-icon":"white--text","title":"Não foram encontrados torneios"}}):_vm._e()]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }