var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('AlertError',{staticClass:"mt-4 mb-n4 ml-1 mr-1",attrs:{"error":_vm.error,"human-error":_vm.humanError}}),_c('v-card-title',[_c('div',{staticClass:"d-flex align-center w-100",class:_vm.$vuetify.breakpoint.mdAndUp ? 'justify-space-between' : 'justify-center'},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Atualização de Dados ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","loading":_vm.scrapersLoading,"color":"success","depressed":"","width":"120px","outlined":""},on:{"click":function($event){return _vm.fetchPlayerWithHendonmobLink()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-convert ")]),_vm._v(" Atualizar ")],1):_vm._e()],1)]),_c('v-data-table',{attrs:{"headers":_vm.scrapersHistory.length ? _vm.dataTable.headers : [],"items":_vm.scrapersHistory,"hide-default-footer":true,"loading":_vm.scrapersHistoryLoading,"options":_vm.options,"height":_vm.$vuetify.breakpoint.mdAndUp ? '27vh' : '',"footer-props":_vm.dataTable.footerProps,"loading-text":"A carregar...","no-data-text":"Não foram encontrados resultados","must-sort":""},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD/MM/YYYY hh:mm:ss'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.getColorIconStatus(item)}},[_vm._v(" "+_vm._s(_vm.getIconStatus(item))+" ")]),_c('span',[_vm._v(_vm._s(_vm.getNameStatus(item)))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status == 'FAILED')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fetchPlayerWithHendonmobLink()}}},[_c('v-icon',[_vm._v(" mdi-restart ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'pb-4' : '',style:(_vm.$vuetify.breakpoint.mdAndDown ? 
          'display: flex; flex-direction: column; width: 100%; height: 28vh; align-items: center; justify-content: center;' : 
          'display: flex; flex-direction: column; width: 100%; height: 23vh; align-items: center; justify-content: center;')},[_c('div',[_c('v-sheet',{staticClass:"mx-auto rounded-circle d-flex justify-center ma-3",attrs:{"height":"64","width":"64","color":"rgb(0, 0, 0, 0.08)"}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi-table-off ")])],1)],1),_c('div',{staticClass:"ml-2 mr-2"},[_c('span',{staticClass:"title"},[_vm._v(" Sem histórico de atualização de dados ")])]),_c('div',[_c('span',{staticClass:"ml-2 mr-2"},[_vm._v(" Para atualizar os dados clique no botão atualizar! ")])])])]},proxy:true},{key:"loading",fn:function(){return [(_vm.scrapersHistoryLoading)?_c('v-row',[_c('div',{staticStyle:{"height":"26vh","display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[_c('span',[_vm._v(" A carregar... ")])])]):_vm._e()]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2),_c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pl-8 pr-8' : 'pl-4 pr-4',style:(_vm.$vuetify.breakpoint.mdAndUp ? 'position: relative; left: 0; right: 0; bottom: 0;' : '')},[_c('v-row',{staticClass:"d-flex flex-nowrap justify-center text-center",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"text--secondary"},[_vm._v("Itens por página")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.showPreviousButton,"small":"","rounded":""},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.showNextButton,"small":"","rounded":""},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }