import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import AlertNoData from '@/components/AlertNoData';
import Snackbar from "@/components/Snackbar";
import Hero from "@/components/Hero/Hero.vue";
import moment from 'moment';

export default {
  name: 'AnomaliesAdmin',

  components: {
    AlertNoData,
    Snackbar,
    Hero,
  },

  mixins: [
    AuthMixin,
    DataMixin,
  ],

  computed: {
    ...mapGetters({
        anomalies: 'anomalies/anomalies',
        loading: 'anomalies/loading',
    })
  },

  async created() {
    this.initialize();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        tabs: {
          current: 0,
          headers: ['Todos', 'Team MTT', 'Team MTT Academy', 'Team Cash', 'Ignorados']
        },

        dataTable: {
          headers: [
            { text: 'Sala', align: 'left', value: 'name_networks', sortable: false },
            { text: 'Jogador', value: 'name_users', sortable: false },
            { text: 'Grupo', value: 'name_groups' },
            { text: 'Jogo', value: 'name_games', sortable: false },
            { text: 'Buy-in', value: 'buyin_games' },
            { text: 'Rebuys e ReEntries', value: 'rebuys_reentries' },
            { text: 'Posição', value: 'position_nhg' },
            { text: 'Lucro', value: 'net_nhg' },
            { text: 'Razão', value: 'message_analysis', sortable: false },
            { text: 'Status', value: 'flag_analysis', sortable: false },
            { text: 'Ação', value: 'action', sortable: false }
          ],
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
          itemsPerPage: 10
        },

        filteredData: {
          team_mtt: {},
          team_mtt_academy: {},
          team_cash: {},
          ignored: {}
        },

        dialogues: {
          view: false,
          edit: false,
          delete: false
        },

        menus: {
          date: {
            open: false,
            value: null   // Set to current date by 'routeParameterHandler()'
          }
        },

        iteration: null
      }
    },

    async initialize() {
      await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager'])
      this.routeParameterHandler();
      await this.fetchData();
    },

    /**
     * Invoked on 'created()', autofills query parameters.
     * Invokes 'dateHandler()'.
     */
    routeParameterHandler() {
      if ( this.$route.params.date && this.validateDate(this.$route.params.date) ) {
        // Parameter 'date' set
        this.menus.date.value = this.$route.params.date;
      } else if (this.$route.params.iteration) {
        // Parameter 'iteration' set
        this.iteration = this.$route.params.iteration;
      } else {
        // No parameters set, define to current date
        this.menus.date.value = this.generateHtmlFormatDateString();
        this.dateHandler(); // Fetches data, updates URL
      }
    },

    /**
     * Invoked when date input changes, and by 'routeParameterHandler()'.
     * Invokes 'fetchData()'.
     */
    dateHandler(val = false) {
      if (val) {
        this.$refs.menuDate.save(val);
        this.$router.push({ path: '/anomalies/date/' + this.menus.date.value });
        this.fetchData();
      }
    },

    /**
     * Accessing the store, fetching data.
     */
    async fetchData() {
      // Clear any previous data
      //Object.assign(this.$data, this.initialState()); // Return all fields to initial state
      this.$store.dispatch('anomalies/clear');
      for (let key in this.filteredData)
        this.filteredData[key] = {};

      // Generate the fetch payload (date / iteration)
      let fetchPayload = {};
      if (this.$route.params.date) {
        fetchPayload = { date: this.menus.date.value };
      } else if (this.$route.params.iteration) {
        fetchPayload = { iteration: this.$route.params.iteration };
      }

      // Get data
      let fetch = await this.$store.dispatch(
        'anomalies/get',
        fetchPayload
      );

      // Filtered data
      this.prepareFilteredData();

      // Update iteration
      if (this.anomalies && this.anomalies.length)
        this.iteration = this.anomalies[0].iteration_analysis;
      
      // Update date if query is set through 'iteration' parameter
      if (this.menus.date.value === null && this.iteration)
        this.menus.date.value = moment(this.anomalies[0]['timestamp_analysis']).format('YYYY-MM-DD');

      // Return status
      return fetch
    },

    /**
     * Handles tabs.
     */
    filter() {
      if (this.anomalies === null) {
        return null;
      }
    
      const filterMap = {
        0: this.anomalies.filter(e => e.is_anomaly_analysis == 1),
        1: this.filteredData.team_mtt,
        2: this.filteredData.team_mtt_academy,
        3: this.filteredData.team_cash,
        4: this.filteredData.ignored,
      };
    
      return filterMap[this.tabs.current] || filterMap[0];
    },

    /**
     * Pre-filters data for tabs so it is prepared when user clicks it.
     */
    prepareFilteredData() {
      if (this.anomalies !== null) {
         // Incluces needs team_ids to be STRING, as API returns this datatype, and string-to-int comparisons will return false
        const { mtt, mtt_academy, cash } = Vue.prototype.$team_ids;

        this.filteredData.team_mtt = this.anomalies.filter(e => 
          e.teams.includes(mtt) && e.is_anomaly_analysis == 1 && e.flag_analysis != 3
        );
    
        this.filteredData.team_mtt_academy = this.anomalies.filter(e => 
          e.teams.includes(mtt_academy) && e.is_anomaly_analysis == 1 && e.flag_analysis != 3
        );
    
        this.filteredData.ignored = this.anomalies.filter(e => e.flag_analysis == 3);
    
        // Team cash includes multiple team id's and needs to be handled differently
        this.filteredData.team_cash = Array.isArray(cash) 
          ? cash.reduce((acc, teamId) => {
              const data = this.anomalies.filter(e => 
                e.teams.includes(teamId) && e.flag_analysis != 3
              );
              return acc.concat(data);
            }, [])
          : [];
      }
    },

    flag(item) {
      this.$store.dispatch('anomalies/flag', item);

      // Show snackbar
      this.$store.dispatch('UI/showSnackbar', {
        message: 'Flag modificada com sucesso.',
        color: 'green'
      });
    },

    calcNumRebuysReentries(rebuys = 0, reentries = 0) {
      return Math.floor(Number(rebuys) + Number(reentries));
    },

    generateRebuysReeentriesString(rebuys = 0, reentries = 0) {
      return `${reentries} multi-entries | ${rebuys} rebuys`;
    },
  }
}