import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddEditTournamentLive from '@/components/Dialog/DialogAddEditTournamentLive/DialogAddEditTournamentLive.vue';
import DialogAddEditTeam from '@/components/Dialog/DialogAddEditTeam/DialogAddEditTeam.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableLiveListTournaments',
    
    components: {
      DialogConfirmation,
      EmptyStateNoData,
      DialogAddEditTeam,
      DialogAddEditTournamentLive,
    }, 

    data() {
      return this.initialState();
    },

    mixins: [
      AuthMixin,
      RailMixin,
      DataMixin,
    ],

    computed: {
      ...mapGetters({
        tournaments: 'liveTournaments/tournaments',
        selectedTournament: 'liveTournaments/selectedTournament',
        selectedEvent: 'liveEvents/selectedEvent',
        tournamentsLoading: 'liveTournaments/tournamentsLoading',
        heroDatePickerValue: 'UI/heroDatePickerValue',
        error: 'liveTournaments/error',
        humanError: 'liveTournaments/humanError',
      }),

      optionsComputed: {
        get: function () {
          return this.options;
        },
        set: function (newValue) {
            if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
            }
        }
      }
    },

    props: {
      filters: { type: Object, required: false, default: () => { }},
      actions: {
        type: Function,
        required: false,
        default: ()=> {}
      }
    },

    async created() {
      await this.fetchData();
    },
  
    mounted() {
      const table = this.$refs.liveListTable.$el.firstChild;
      this.scrollContainer = table;
      table.addEventListener('scroll', this.onScroll);
    },


    beforeDestroy() {
      this.scrollContainer.removeEventListener('scroll', this.onScroll);
    },
    
    watch: {
      heroDatePickerValue: function () {
        if (!this.fetchLoading) {
          this.reloadTournaments();
        }
      },
    },

    methods: {
      initialState() {
        return {
          expectedLenght: null,
          fetchLoading: false,
          scrollContainer: null,
          noMoreData: false,
          action: 'update',
          allTournaments: [],
          dialogues: {
            viewTournament: false,
            removeTournament: false,
            addEditTournament: false,
            deleteSubmitting: false,
          },
          idToDelete: null,
          headers: [
            { text: 'Nome', value: 'name', sortable: false },
            { text: 'Evento', value: 'event.name', sortable: false },
            { text: 'Data inicial', value: 'starts_at', sortable: false },
            { text: 'Inscrições até', value: 'late_registration_at', sortable: false },
            { text: 'Buy-in', value: 'buyin', sortable: false },
            { text: 'Prize Pool', value: 'prize_pool', sortable: false },
            { text: 'Stack Inicial', value: 'starting_stack', sortable: false },
            { text: 'Participantes', value: 'participants', sortable: false },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
          options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: true,
            multiSort: true
          },
        }
      },

      async fetchData() {
        this.fetchLoading = true;
        // Base payload
        let payload = {
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            per_page: this.options.itemsPerPage,
          }
        };
      
        Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));
        this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
      
        if (!this._.isEmpty(this.heroDatePickerValue) && this.heroDatePickerValue !== null) {
          payload.params.starts_at = this.heroDatePickerValue[0];
          payload.params.ends_at = this.heroDatePickerValue[1];
        }
      
        // API Call
        await this.$store.dispatch('liveTournaments/fetchTournaments', payload);
      
        const existingTournamentIds = new Set(this.allTournaments.map(tournament => tournament.id));
      
        const uniqueTournaments = this.tournaments.filter(tournament => !existingTournamentIds.has(tournament.id));
      
        this.expectedLength = this.allTournaments.length + uniqueTournaments.length;
      
        this.allTournaments = [...this.allTournaments, ...uniqueTournaments];
      
        if (uniqueTournaments.length < this.options.itemsPerPage) {
          this.noMoreData = true;
        } else {
          this.noMoreData = false;
        }
      
        this.fetchLoading = false;
      },

      async reloadTournaments(){
        this.allTournaments = [];
        this.options.page = 1;
        this.options.itemsPerPage = 10;
        await this.fetchData();
      },

      dialogViewEvent(item){
        this.setActiveItem(item, 'event');
        this.$router.push({
          path: `/live/events/${item.event.id}`
        });
      },

      calcHeight() {
        const itemCount = this.allTournaments.length;
        if (itemCount < 1 && !this.tournamentsLoading) return '250px'; // Empty state
        return '515px';
      },

      calcHeightMobile(){
        const itemCount = this.allTournaments.length;
        if(itemCount < 1) return '280px'; // Empty state
         return 'calc(100vh - 320px)';
      },

      getCurrencyInputWidth(value) {
        let output = '50px';
        if (value) {
            output = parseFloat(value).toFixed(3);

            // turn output into String
            output = String(output);

            output = (output.length + 1.2) * 8 + 'px';
        }
        return output;
      },

      onScroll() {
        const scrollContainer = this.scrollContainer;
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
          this.loadMore();
        }
      },

      async loadMore() {
        // Verificar se há mais dados para carregar
        if (this.noMoreData) {
          return;
        }
      
        let expectedLength = this.options.itemsPerPage * this.options.page;
      
        if (this.expectedLength) {
          expectedLength = this.expectedLength;
        }
      
        if (this.allTournaments.length == expectedLength) {
          this.options.page += 1;
          await this.fetchData();
        }
      },

      async deleteTournament(){
        this.dialogues.deleteSubmitting = true;
        let result = await this.$store.dispatch('liveTournaments/deleteTournament', this.idToDelete);
        this.dialogues.removeTournament = false;
        this.dialogues.deleteSubmitting = false;
        if (result === true) {
          this.reloadTournaments();
          // Show success snackbar
          this.$store.dispatch('UI/showSnackbar', {
              message: 'Torneio removido com sucesso.',
              color: 'success'
          });

          this.closeDialogRemoveConfirmation();
        }
      },
      
      constructPayload() {
        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          },
        }
        return payload;
      },

      async dialogViewTournament(item) {
        this.setActiveItem(item);
        this.$router.push({
          path: `/live/tournaments/${item.id}`
        });
      },
      
      openDialogRemoveConfirmation(id) {
        this.dialogues.removeTournament = true;
        this.idToDelete = id
      },

      closeDialogRemoveConfirmation() {
        this.dialogues.removeTournament = false;
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = ! this.dialogues[dialog];
      },

      setActiveItem(item, entity=null) {
        if (item) {
          if(entity) {
            this.$store.commit(
              'liveEvents/setSelectedEvent',
              item.event
            );
          } else {
            this.$store.commit(
              'liveTournaments/setSelectedTournament',
              item
            );
          }
        }
      },

      openDialogAddEditTournament(action, item = null) {
        this.action = action;
        if (item) {
          this.setActiveItem(item);
        }
        this.toggleDialog('addEditTournament');
      },

      closeDialogAddEditTournament() {
        this.$store.commit('liveTournaments/setSelectedTournament', null);
        this.toggleDialog('addEditTournament');
      }
    },

  }