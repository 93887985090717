import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    scrapers: [],
    scrapersLoading: false,
    selectedScraper: null,
    error: null,
    scrapingError: null,
    scrapingHumanError: null,
    humanError: null,
    scrapersHistory: [],
    scrapersHistoryLoading: false,
};

const getters = {
    scrapers: state => state.scrapers,
    scrapersLoading: state => state.scrapersLoading,
    error: state => state.error,
    selectedScraper: state => state.selectedScraper,
    selectedScraperLoading: state => state.selectedScraperLoading,
    humanError: state => state.humanError,
    scrapingError: state => state.scrapingError,
    scrapingHumanError: state => state.scrapingHumanError,
    scrapersHistory: state => state.scrapersHistory,
    scrapersHistoryLoading: state => state.scrapersHistoryLoading,
};

const mutations = {
    setScrapers: (state, payload) => { state.scrapers = payload; },
    setScrapersLoading: (state, payload) => { state.scrapersLoading = payload; },
    addScraper: (state, payload) => {
        if (!state.scrapers || !state.scrapers.length)
            state.scrapers = []; 
        state.scrapers.unshift(payload);
    },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setSelectedScraper: (state, payload) => { state.selectedScraper = payload; },
    setSelectedScraperLoading: (state, payload) => { state.selectedScraperLoading = payload; },
    setScrapingError: (state, payload) => { state.scrapingError = payload; },
    setScrapingHumanError: (state, payload) => { state.scrapingHumanError = payload; },
    setScrapersHistory: (state, payload) => { state.scrapersHistory = payload; },
    setScrapersHistoryLoading: (state, payload) => { state.scrapersHistoryLoading = payload; },
};

const actions = {

    clearError({ commit }) {
        commit('setError', null);
        commit('setScrapingError', null);
        commit('setScrapingHumanError', null);
        commit('setHumanError', null);
    },

    async fetchScraperByUserId({ commit }, payload) {
        commit('setScrapersHistoryLoading', true);
        commit('setScrapersHistory', []);
        commit('setError', null);
        const id = payload.params && payload.params.id ? payload.params.id : null;

        delete payload.params.id;

        let request_url = Vue.prototype.$url_api_live + `players/${id}/scrapes`;

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setScrapersHistory', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            }
        })
        .catch(function (error) {
            commit('setScrapersHistory', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setScrapersHistoryLoading', false);
            output = false;
        })
        .then(function () {
            commit('setScrapersHistoryLoading', false);
            return output;
        });
    },

    async fetchPlayerWithHendonmobLink({ commit }, payload) {
        commit('setScrapersLoading', true);
        commit('setScrapingError', null);
        commit('setScrapingHumanError', null);

        let request_url = Vue.prototype.$url_api_live + `scraping`;

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 201;
            if (output) {
                commit('setSelectedScraper', response.data);
            } else {
                commit('setScrapingHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            }
        })
        .catch(function (error) {
            commit('setSelectedScraper', []);
            commit('setScrapingError', error.response.status.toString());
            commit('setScrapingHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setScrapersLoading', false);
            output = false;
        })
        .then(function () {
            commit('setScrapersLoading', false);
            return output;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};