import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import EventLiveDropdown from '@/components/EventLiveDropdown/EventLiveDropdown.vue';
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';

export default {
    name: 'DialogMenuCreatePlayerLive',

    mixins: [
        AuthMixin,
    ],

    components: {
        AlertError,
        DatePicker,
        UserDropdown,
        EventLiveDropdown,
        DialogAddEditPlayerLive,
    },

    props: {
      isOpen: {
          type: Boolean,
          required: true,
          default: false
      },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
            scrapingError: 'liveScraper/scrapingError',
            scrapingHumanError: 'liveScraper/scrapingHumanError',
            selectedScraper: 'liveScraper/selectedScraper',
        }),
    },

    created(){
        if (this._.isEmpty(this.countries)) {
            this.$store.dispatch('countries/get');
        }
    },

    watch: {
        // when re opened, clean form and populate fields
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.resetForm();
                this.resetCreatePlayerForm();
                this.resetImportPlayerForm();
            }
        },

        dialogToOpen: function (newVal, oldVal) {
            if(newVal !== oldVal){
                if(newVal == 'addEditPlayerLive'){
                    this.resetCreatePlayerForm();
                }else if(newVal == 'importHendonmob'){
                    this.resetImportPlayerForm();
                }
            }
        }
    },

    methods: {
        initialState() {
            return {
                action: 'create',
                dialogToOpen: '',
                step: '1',
                dialogues: {
                    addEditPlayerLive: false,
                },
                submitting: false,
                formAddPlayer: {
                    name: null,
                    picture: null,
                    biography: null,
                    country: null,
                    team: null,
                    external_link: null,
                },
                formImportPlayer: {
                    link: null,
                },
                rules: {
                    name: [v => !!v || 'Campo obrigatório'],
                    link: [
                        v => !!v || 'Campo obrigatório',
                        v => /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?thehendonmob\.com/.test(v) || 'O link deve ser do site thehendonmob.com'
                    ],
                }
            };
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        getTitle(){
            let output = 'Adicionar jogador';
            if(this.dialogToOpen == 'importHendonmob') output = 'Importar jogador';
            return output;
        },

        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        resetCreatePlayerForm(){
            if(this.$refs.formAddPlayerLive){
                this.$refs.formAddPlayerLive.resetValidation();
            }
            this.formAddPlayer = {
                name: null,
                picture: null,
                biography: null,
                country: null,
                team: null,
                external_link: null,
            };

            this.$store.commit('livePlayers/setError', null);
            this.$store.commit('livePlayers/setHumanError', null);
        },

        resetImportPlayerForm(){
            if(this.$refs.formImportHendonmob){
                this.$refs.formImportHendonmob.resetValidation();
            }
            this.formImportPlayer = {
                link: null,
            };

            this.$store.commit('liveScraper/setScrapingError', null);
            this.$store.commit('liveScraper/setScrapingHumanError', null);
        },

        openDialog(dialog){
            this.dialogToOpen = dialog;
            if(this.dialogToOpen == 'importHendonmob'){
                return this.step = 3;
            }
            this.step++;
        },

        resetForm(){
            this.step = 1;
        },

        async submit(){
            let payload = {};

            if(this.dialogToOpen === 'addEditPlayerLive'){
                this.$store.dispatch('livePlayers/clearErrors');
                if (!this.$refs.formAddPlayerLive.validate()) {
                    return false;
                }
    
                // Toggle submit button
                this.toggleSubmitting();
    
                Object.entries(this.formAddPlayer).forEach(([key, value]) => {
                    if (value !== null) {
                      payload[key] = value;
                    }
                });
    
                let actionToDispatch = 'livePlayers/createPlayer'
                let messageSnackbar = 'jogador adicionado com sucesso.'  
    
                let result = await this.$store.dispatch(actionToDispatch, payload);
    
                // // Re-enable button
                this.toggleSubmitting();
                // On success
                if (result === true) {
                    this.$store.dispatch('UI/showSnackbar', {
                        message: messageSnackbar,
                        color: 'success'
                    });
    
                    // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                    setTimeout(() => {
                        // refresh
                        this.$emit('reload', this.dialogToOpen);
                    }, 1000);
    
                    this.$root.$emit('close-dialog-event');
    
                    return this.closeDialog();
                }
            }

            else if(this.dialogToOpen === 'importHendonmob'){
                if (!this.$refs.formImportHendonmob.validate()) {
                    return false;
                }

                this.toggleSubmitting();

                payload = {
                    url: this.formImportPlayer.link
                }

                let actionToDispatch = 'liveScraper/fetchPlayerWithHendonmobLink'
                let messageSnackbar = 'jogador adicionado com sucesso.'  
    
                let result = await this.$store.dispatch(actionToDispatch, payload);
    
                // // Re-enable button
                this.toggleSubmitting();
                // On success
                if (result === true) {
                    this.$store.dispatch('UI/showSnackbar', {
                        message: messageSnackbar,
                        color: 'success'
                    });
    
                    // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                    setTimeout(() => {
                        // refresh
                        this.$emit('reload', this.dialogToOpen);
                    }, 1000);
    
                    this.$root.$emit('close-dialog-event');
    
                    return this.closeDialog();
                }
            }
        }
    }
}