import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';

export default {
    name: 'DialogAddEditEventLive',

    components: {
        AlertError,
        DatePicker,
    },

    mixins: [
        AuthMixin,
    ],

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },

        action: {
            type: String,
            required: true,
            default: 'create',
            validator: function (value) {
                const allowedActions = ['create', 'update']
                return allowedActions.includes(value);
            },
        },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            selectedEvent: 'liveEvents/selectedEvent',
            error: 'railSessions/error',
            humanError: 'railSessions/humanError',
        }),
    },

    watch: {
        // when re opened, clean form and populate fields
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.resetForm();
                this.populateForm();
            }
        },
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    eventName: null,
                    dateStart: null,
                    dateEnd: null,
                    selectedImage: null,
                },
                rules: {
                    eventName: [
                        (v) => !!v || 'Por favor introduza um nome para o evento'
                    ],
                    dateStart: [
                        v => !!v || 'Por favor introduza uma data de início'
                    ],
                    dateEnd: [
                        v => !!v || 'Por favor introduza uma data final',
                        v => !v || !this.form.dateStart || new Date(v) >= new Date(this.form.dateStart) || 'A data final não pode ser menor que a data inicial'
                    ]
                }
            };
        },

        dialogTitle() {
            return this.action == 'create' ? 'Criar evento de live' : 'Editar evento de live';
        },

        populateForm() {
            if (this.action == 'update') {
                this.form.eventName = this.selectedEvent.name;
                this.form.dateStart = this.$moment(this.selectedEvent.starts_at).format('YYYY-MM-DD');
                this.form.dateEnd = this.$moment(this.selectedEvent.ends_at).format('YYYY-MM-DD');
            }
        },

        openDeleteDialog() {
            this.$emit('open-delete-dialog', id);
        },

        convertToISODateTime(dateStr) {
            return this.$moment(dateStr, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
        },

        async submit() {
            if (!this.$refs.formAddEventLive.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                name: this.form.eventName,
                starts_at: this.convertToISODateTime(this.form.dateStart),
                ends_at: this.convertToISODateTime(this.form.dateEnd),
                location: '',
                picture: '',
            }

            if (this.action == 'update') {
                payload.id = this.selectedEvent.id;
            }
            
            let actionToDispatch = this.action == 'create'
            ? 'liveEvents/createEvent'
            : 'liveEvents/updateEvent';
          
            let messageSnackbar = this.action == 'create'
            ? 'evento de live adicionado com sucesso.'
            : 'evento de live editado com sucesso.'

            let result = await this.$store.dispatch(actionToDispatch, payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: messageSnackbar,
                    color: 'success'
                });

                // refresh
                this.$emit('reload');
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formAddEventLive) {
                this.$refs.formAddEventLive.reset();
            }

            // clear errors
            this.$store.dispatch('railSessions/clearError');
        },

        openImagePicker() {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/jpeg, image/jpg, image/gif';
            input.addEventListener('change', (event) => {
                const file = event.target.files[0];
                const types = ['image/jpeg', 'image/jpg', 'image/gif']
                if (file && types.includes(file.type)) {
                    if (file.size <= 2 * 1024 * 1024) {
                        const imageUrl = URL.createObjectURL(file);
                        this.form.selectedImage = imageUrl;
                    }
                }
            });
            input.click();
        },
    }
}