import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import Snackbar from "@/components/Snackbar";

export default {
    mixins: [
        AuthMixin,
    ],

    components: {
        AlertError,
        Snackbar
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },

        action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
        },
    },

    data() {
        return this.initialState();
    },

    created(){
        if(this._.isEmpty(this.teams)){
            this.$store.dispatch('teams/get');
        }
        if (this._.isEmpty(this.countries)) {
            this.$store.dispatch('countries/get');
        }

    },

    watch: {
        isOpen: function (newVal, oldVal) {
            this.$store.dispatch('livePlayers/clearErrors');
            if (newVal === true) {
                this.resetForm();
                this.populateForm();
            }
        },
    },

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            teams: 'teams/teams',
            loading: 'teams/loading',
            selectedPlayer: 'livePlayers/selectedPlayer',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
            selectedPlayerNicknames: 'livePlayers/selectedPlayerNicknames',
        }),
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                   name: null,
                   picture: null,
                   biography: null,
                   country: null,
                   team: null,
                   external_link: null,
                },
            };
        },
        
        dialogTitle() {
            return this.action == 'create' ? 'Criar jogador' : 'Editar jogador';
        },

        populateForm() {
            if (this.action == 'update' && this.isOpen && this.selectedPlayer) {
                this.form.name = this.selectedPlayer.name;
                this.form.picture = this.selectedPlayer.picture;
                this.form.biography = this.selectedPlayer.biography;
                this.form.country = this.selectedPlayer.country;
                this.form.external_link = this.selectedPlayer.external_link;
            }
        },

        async fetchData() {
        },

        async fetchNicknames(playerId) {
            await this.$store.dispatch('livePlayers/fetchSelectedPlayerNicknames', playerId);
            return this.selectedPlayerNicknames || [];
        },

        async submit() {
            this.$store.dispatch('livePlayers/clearErrors');

            if (!this.$refs.formAddEditPlayerLive.validate()) {
                return false;
            }

            let payload = {};

            // Toggle submit button
            this.toggleSubmitting();

            if (this.action == 'update') {
                payload.nicknames = await this.fetchNicknames(this.selectedPlayer.id);
                payload = this.selectedPlayer;
                payload.id = this.selectedPlayer.id;
            }

            Object.entries(this.form).forEach(([key, value]) => {
                if (value !== null) {
                  payload[key] = value;
                }
            });

            let actionToDispatch = this.action == 'create'
            ? 'livePlayers/createPlayer'
            : 'livePlayers/updatePlayer';
          
            let messageSnackbar = this.action == 'create'
            ? 'jogador adicionado com sucesso.'
            : 'jogador editado com sucesso.'

            let result = await this.$store.dispatch(actionToDispatch, payload);

            // // Re-enable button
            this.toggleSubmitting();
            // On success
            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: messageSnackbar,
                    color: 'success'
                });

                // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                setTimeout(() => {
                    // refresh
                    this.$emit('reload');
                }, 1000);

                this.$root.$emit('close-dialog-event');

                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formAddEditPlayerLive) {
                this.$refs.formAddEditPlayerLive.reset();
            }

            // clear errors
            this.$store.dispatch('liveEvents/clearErrors');
        },

        openImagePicker() {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/jpeg, image/jpg, image/gif';
            input.addEventListener('change', (event) => {
                const file = event.target.files[0];
                const types = ['image/jpeg', 'image/jpg', 'image/gif']
                if (file && types.includes(file.type)) {
                    if (file.size <= 2 * 1024 * 1024) {
                        const imageUrl = URL.createObjectURL(file);
                        this.form.image = imageUrl;
                    }
                }
            });
            input.click();
        },
    }
}
