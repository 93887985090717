import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import AuthMixin from "@/mixins/Auth";
import PokerTable from '@/components/PokerTable/PokerTable.vue';
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';
import DialogAddNicknamesToPlayerLive from '@/components/Dialog/DialogAddNicknamesToPlayerLive/DialogAddNicknamesToPlayerLive.vue';
import DialogAddEditNotes from '@/components/Dialog/DialogAddEditNotes/DialogAddEditNotes.vue';
import PokerTablePlayerInfo from '@/components/PokerTablePlayerInfo/PokerTablePlayerInfo.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';


export default {
    components: {
        PokerTable,
        DialogAddEditPlayerLive,
        DialogAddEditNotes,
        PokerTablePlayerInfo,
        EmptyStateNoData,
        DialogAddNicknamesToPlayerLive,
    },
    mixins: [
        DataMixin,
        AuthMixin,
    ],

    data() {
        return {
            dialogues: {
                addPlayerLive: false,
                editPlayerLive: false,
                addNotes: false,
                editNotes: false,
                addNicknames: false,
            },
            showPokerTablePlayerInfo: false,
            fabButtonType: 'players',
            fabButtons: [
                { type: 'addPlayerLive', icon: 'mdi-account-plus', dialog: 'addPlayerLive' },
                { type: 'editPlayerLive', icon: 'mdi-account-edit', dialog: 'editPlayerLive' },
                { type: 'addNotes', icon: 'mdi-note-plus', dialog: 'addNotes' },
                { type: 'editNotes', icon: 'mdi-lead-pencil', dialog: 'editNotes' },
                { type: 'addNicknames', icon: 'mdi-plus', dialog: 'addNicknames' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
        }),
        
        fabButton() {
            const valueMap = {
                'notes': 'addNotes',
                'players': 'addPlayerLive',
                'info': 'editPlayerLive',
                'nicknames': 'addNicknames',

            };
            return valueMap[this.fabButtonType] || 'addPlayerLive';
        },
        filteredButtons() {
            return this.fabButtons.filter(button => button.type === this.fabButton);
        },

        player() {
            return this.selectedPlayer;
        }
    },
    
    created() {
        this.$root.$on('change-fab-button', this.handleFabButton);
        this.$root.$on('toggle-poker-player-info', this.handlePokerTableInfo);
    },

    methods: {
        handleFabButton(value) {
            this.fabButtonType = value;
        },

        hidePlayerInfoComponent(){
            this.showPokerTablePlayerInfo = false;
        },

        handlePokerTableInfo(player){
            this.showPokerTablePlayerInfo = Boolean(player && player.id);
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        closeDialog() {
            const dialogues = ['addPlayerLive', 'editPlayerLive', 'addNotes', 'editNotes', 'addNicknames'];
            for (const dialog of dialogues) {
                if (this.dialogues[dialog]) {
                    this.toggleDialog(dialog);
                    break;
                }
            }
        },

        checkIsOpen(dialog) {
            switch (dialog) {
                case 'playerLive':
                    return this.dialogues.addPlayerLive || this.dialogues.editPlayerLive;
                case 'notes':
                    return this.dialogues.addNotes || this.dialogues.editNotes;
                case 'nicknames':
                    return this.dialogues.addNicknames;
                default:
                    break;
            }
            
        },

        checkAction(dialog) {
            switch (dialog) {
                case 'playerLive':
                    return this.dialogues.addPlayerLive ? 'create' : 'update';
                case 'notes':
                    return this.dialogues.addNotes ? 'create' : 'update';
                default:
                    break;
            }
        },

        async fetchData() {
        },

        reloadData() {
        },
    },
};
