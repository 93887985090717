import { render, staticRenderFns } from "./LivePlayers-Single.vue?vue&type=template&id=063d29d2&scoped=true&"
import script from "./LivePlayers-Single.js?vue&type=script&lang=js&"
export * from "./LivePlayers-Single.js?vue&type=script&lang=js&"
import style0 from "./LivePlayers-Single.vue?vue&type=style&index=0&id=063d29d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063d29d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBadge,VBtn,VCard,VCardActions,VCardText,VChip,VChipGroup,VCol,VContainer,VDivider,VIcon,VImg,VList,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VMenu,VRow,VSimpleTable,VSkeletonLoader,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTextField,VTooltip})
