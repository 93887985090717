import Vue from 'vue';
import Vuex from 'vuex';
import uiModule from './UI';
import authModule from './auth';
import applicationsModule from './applications';
import calendarModule from './calendar';
import departuresModule from './departures';
import usersV1Module from './users';
import usersV2Module from './v2/users';
import groupsV1Module from './groups';
import groupsV2Module from './v2/groups.js';
import statsV2Module from './v2/stats.js';
import teamsV1Module from './teams';
import teamsV2Module from './v2/teams';
import contractsModule from './contracts';
import networksV1Module from './v1/networks';
import networksV2Module from './v2/networks';
import networksSharkscopeModule from './networksSharkscope';
import nicknamesV1Module from './v1/nicknames';
import nicknamesV2Module from './v2/nicknames';
import anomaliesModule from './anomalies';
import videoRequestsModule from './videoRequests';
import videoReportsModule from './videoReports';
import TabSliderModule from './rail/TabSlider.js';
import accountsModule from './accounts.js';
import railSessionsModule from './rail/rail-sessions.js';
import railOverviewModule from './rail/rail-overview.js';
import railTransfersModule from './rail/rail-transfers.js';
import railWalletsModule from './rail/rail-wallets.js';
import railRequestsModule from './rail/rail-requests.js';
import railReportsModule from './rail/rail-reports.js';
import railStatisticsModule from './rail/rail-statistics.js';
import railExpenses from './rail/rail-expenses.js';
import systemEventsModule from './systemEvents.js';
import countriesModule from './countries.js';
import qualificationsModule from './qualifications.js';
import RailSwapsModule from './rail/rail-swaps.js';
import gamesModule from './games.js';
import gameEntriesModule from './v2/gameEntries.js';
import currenciesModule from './currencies.js';
import schedulingFactorsModule from './scheduling/factors.js';
import schedulingModelsModule from './scheduling/models.js';
import schedulingGamesModule from './scheduling/games.js';
import schedulingGameGroupsModule from './scheduling/game_groups.js';
import schedulingNetworksSharkscopeModule from './scheduling/networks_sharkscope.js';
import eventsCalendarModule from './calendar/events.js';
import eventsCategoriesCalendarModule from './calendar/eventsCategories.js';
import eventsFormsCalendarModule from './calendar/eventsForms.js';
import toolsHRCModule from './tools/hrc.js';
import auditsModule from './v2/audits.js';
import documentsModule from './documents.js';
import schedulesModule from './v2/schedules.js';
import livePlayersModule from './live/live-players.js';
import NotesModule from './live/notes.js';
import liveEventsModule from './live/live-events.js';
import liveTournamentsModule from './live/live-tournaments.js';
import liveGame from './live/live-game.js';
import liveScraper from './live/live-scraper.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        UI: uiModule,
        auth: authModule,
        applications: applicationsModule,
        calendar: calendarModule,
        departures: departuresModule,
        users_v1: usersV1Module,
        users: usersV2Module,
        groups_v1: groupsV1Module,
        groups: groupsV2Module,
        teams_v1: teamsV1Module,
        teams: teamsV2Module,
        contracts: contractsModule,
        networks_v1: networksV1Module,
        networks: networksV2Module,
        networksSharkscope: networksSharkscopeModule,
        nicknames_v1: nicknamesV1Module,
        nicknames: nicknamesV2Module,
        anomalies: anomaliesModule,
        videoRequests: videoRequestsModule,
        videoReports: videoReportsModule,
        TabSlider: TabSliderModule,
        accounts: accountsModule,
        railSessions: railSessionsModule,
        railOverview: railOverviewModule,
        railTransfers: railTransfersModule,
        railWallets: railWalletsModule,
        railRequests: railRequestsModule,
        railReports: railReportsModule,
        railStatistics: railStatisticsModule,
        railExpenses: railExpenses,
        systemEvents: systemEventsModule,
        countries: countriesModule,
        qualifications: qualificationsModule,
        railSwaps: RailSwapsModule,
        games: gamesModule,
        currencies: currenciesModule,
        schedulingFactors: schedulingFactorsModule,
        schedulingModels: schedulingModelsModule,
        schedulingGames: schedulingGamesModule,
        schedulingGameGroups: schedulingGameGroupsModule,
        schedulingNetworksSharkscope: schedulingNetworksSharkscopeModule,
        eventsCalendar: eventsCalendarModule,
        eventsCategoriesCalendar: eventsCategoriesCalendarModule,
        eventsFormsCalendar: eventsFormsCalendarModule,
        toolsHRC: toolsHRCModule,
        audits: auditsModule,
        stats: statsV2Module,
        documents: documentsModule,
        gameEntries: gameEntriesModule,
        schedules: schedulesModule,
        livePlayers: livePlayersModule,
        notes: NotesModule,
        liveEvents: liveEventsModule,
        liveTournaments: liveTournamentsModule,
        liveGame: liveGame,
        liveScraper: liveScraper,
    },
});
