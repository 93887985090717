import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import CardLiveEvent from "@/components/CardLiveEvent/CardLiveEvent.vue";
import DialogAddEditEventLive from '@/components/Dialog/DialogAddEditEventLive/DialogAddEditEventLive.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DatePicker from '@/components/DatePicker/DatePicker.vue';

export default {
    name: 'TableLiveEvents',

    props: {
        hideCreateButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    components: {
        UserDropdown,
        CardLiveEvent,
        DialogAddEditEventLive,
        DialogConfirmation,
        EmptyStateNoData,
        DatePicker,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            events: 'liveEvents/events',
            eventsLoading: 'liveEvents/eventsLoading',
            selectedEvent: 'liveEvents/selectedEvent',
            error: 'liveEvents/error',
            humanError: 'liveEvents/humanError',
        }),

        numberOfPages() {
            if (!this._.isEmpty(this.events)) {
                return Math.ceil(this.events.length / this.itemsPerPage)
            }
        },

        skeletonLoadingComputed() {
            return this.$vuetify.breakpoint.mdAndUp
                ? 12
                : 6;
        }
    },

    data() {
        return this.initialState();
    },

    watch: {
        events: function (events) {
            this.showNextButton = events.length === this.itemsPerPage;
            this.showPreviousButton = this.page > 1;
        },

        page: function () {
            this.fetchData();
        },

        itemsPerPage: function () {
            this.fetchData();
        },
    },

    async created() {
        await this.fetchData();
    },

    methods: {

        initialState() {
            return {
                search: '',
                selectedCountry: null,
                date: null,
                dialogues: {
                    addEditEventLive: false,
                    deleteEventLive: false,
                    deleteSubmitting: false,
                },
                action: 'create',
                itemsPerPageArray: [4, 8, 12],
                page: 1,
                itemsPerPage: 12,
                typingTimeout: null,
                showNextButton: true,
                showPreviousButton: false,
            };
        },

        async fetchData() {
            let payload = {
                per_page: this.itemsPerPage,
                offset: (this.page - 1) * this.itemsPerPage,
            };

            // Adiciona 'search' ao payload se não estiver vazio
            if (!this._.isEmpty(this.search)) {
                payload.search = this.search;
            }
            
            // Adiciona 'country' ao payload se não estiver vazio
            if (!this._.isEmpty(this.selectedCountry)) {
                payload.country = this.selectedCountry;
            }

            this.$store.commit('liveEvents/setEventsLoading', true);
            setTimeout(() => {
                this.$store.dispatch('liveEvents/fetchEvents', payload);
            }, 700);
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        addNewEvent() {
            this.$store.commit('liveEvents/setSelectedEvent', null);
            this.action = 'create';
            this.toggleDialog('addEditEventLive')
        },

        editEvent(event) {
            this.$store.commit('liveEvents/setSelectedEvent', event);
            this.action = 'update';
            this.toggleDialog('addEditEventLive');
        },

        openDialogConfirmation(event) {
            this.$store.commit('liveEvents/setSelectedEvent', event);
            this.toggleDialog('deleteEventLive')
        },

        searchEvent() {
            // Limpa o temporizador anterior para evitar múltiplas execuções
            clearTimeout(this.typingTimeout);
            // Define um novo temporizador que chama 'searchEvent' após 1 segundo de inatividade
            this.typingTimeout = setTimeout(() => {
                    this.fetchData()
                }, 1000);
        },

        async deleteEvent() {
            this.dialogues.deleteSubmitting = true;
            let result = await this.$store.dispatch('liveEvents/deleteEvent', this.selectedEvent.id);
            this.dialogues.deleteSubmitting = false;
            
            if(result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Evento removido com sucesso.',
                    color: 'success'
                });
            }
            this.toggleDialog('deleteEventLive');
        },

        nextPage () {
            if (this.events.length === this.itemsPerPage) this.page += 1
        },
        
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },

        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
  
    }
};
