import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'PlayerLiveDropdown',

    mixins: [
        DataMixin,
        AuthMixin,
        RailMixin,
    ],

    props: {
        value: {
            type: [Object,Array],
            required: false,
            default: () => {}
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Jogador'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        backgroundColor: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || 'Por favor preencha o nome do jogador'
            ]
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false 
        },
        searchApi:{
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formPlayerLiveDropdown.resetValidation();
    },

    computed: {
        ...mapGetters({
            livePlayers: 'livePlayers/livePlayers',
            livePlayersLoading: 'livePlayers/livePlayersLoading'
        }),
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.livePlayer;
            },
            set: function (newValue) {
                if(newValue){
                    let playerInOutput = this.livePlayers.filter(player => player.id === newValue.id);
                    if (playerInOutput.length === 0) {
                        Vue.set(this.livePlayers, this.livePlayers.length, newValue);
                    }
                }
                this.livePlayer = newValue;
                this.emitChange();
            }
        },

        computedRules: function () {
            return this.rules;
        },

        /**
         * Filters data according to the props passed
         * @returns {Array}
         */
        dataFiltered: function () {
            // Using slice() to avoid the following error
            // https://stackoverflow.com/questions/43151265/you-may-have-an-infinite-update-loop-in-a-component-render-function
            let output = this.livePlayers.slice();

            return output;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
    },

    methods: {
        initialState() {
            return {
                livePlayer: null,
                search: null,
                typingTimeout: null,
                isMenuActive: false,
                loadingSearchInput: false,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                    sortBy: ['name'],
                    sortDesc: [false],
                },
                data: [],
            }
        },

        reopenAutocompleteMenu() {
            // Fecha e reabre o menu para garantir que os itens sejam mostrados
            if (this.$refs.autocomplete) {
                this.$refs.autocomplete.isMenuActive = false; // Fecha o menu
                this.$nextTick(() => {
                    this.$refs.autocomplete.isMenuActive = true; // Reabre o menu
                });
            }
        },

        async onSearchInput() {
            if(this.searchApi){
                clearTimeout(this.typingTimeout);
                
                if(this.search !== null && this.search != undefined){
                    this.typingTimeout = setTimeout(async () => {
                        await this.fetchData({search: this.search});
                    }, 1000);
                }
            }
        },

        async initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;

            if (this._.isEmpty(this.livePlayers) && this.autoReload) {
                await this.fetchData();
            }
        },

        async fetchData(payload=null) {
            // get players list
            if(payload) return await this.$store.dispatch('livePlayers/fetchLivePlayers', payload);
            await this.$store.dispatch('livePlayers/fetchLivePlayers');
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formPlayerLiveDropdown.reset();
        },

        async onClear() {
            await this.fetchData();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        }
    }
}