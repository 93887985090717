import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    events: [],
    eventsLoading: false,
    selectedEvent: null,
    selectedEventLoading: false,
    error: null,
    humanError: null,
    errorStatus: null,
};

const getters = {
    events: (state) => state.events,
    eventsLoading: (state) => state.eventsLoading,
    selectedEvent: (state) => state.selectedEvent,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    errorStatus: (state) => state.errorStatus,
    selectedEventLoading: (state) => state.selectedEventLoading,
};

const mutations = {
    setEvents: (state, payload) => { state.events = payload },
    setEventsLoading: (state, payload) => { state.eventsLoading = payload },
    setSelectedEvent: (state, payload) => { state.selectedEvent = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    addEvent: (state, payload) => {
        if (!state.events || !state.events.length)
            state.events = [];

        state.events.unshift(payload);
    },
    setSelectedEventLoading: (state, payload) => { state.selectedEventLoading = payload },
};

const actions = {
    // Fetch the list of events
    async fetchEvents({ commit }, payload) {
        commit('setEventsLoading', true);
        commit('setEvents', []);
        commit('setError', null);

        let request_url = Vue.prototype.$url_api_live + 'events';

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setEvents', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setEvents', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            commit('setEventsLoading', false);
            return output;
        });
    },

    async fetchEventById({ commit }, payload) {
        commit('setSelectedEventLoading', true);
        commit('setSelectedEvent', []);
        commit('setError', null);

        let request_url = Vue.prototype.$url_api_live + 'events/' + payload.id;
        
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        
        let output = false;
        
        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setSelectedEvent', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setSelectedEvent', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            commit('setSelectedEventLoading', false);
            return output;
        });
    },

    // Create a new event
    async createEvent({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearErrors');

        // Handle payload data
        let data = payload;

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'events';

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (response.status === 201) {
                commit('addEvent', data);
                output = true;
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateEvent({ commit, dispatch }, payload) {
        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api_live + 'events/' + payload.id;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 200;

            if (output) {
                commit('setSelectedEvent', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    // Delete an event
    async deleteEvent({ commit, dispatch }, eventId) {
        commit('setError', null);
    
        const config = {
            method: 'delete',
            url: Vue.prototype.$url_api_live + `events/${eventId}`,
        };

        let output = false;
    
        return axios(config)
            .then(function (response) {
                output = response.status == 204;
                if (output) {
                    // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                    setTimeout(() => {
                        // refresh
                        dispatch('fetchEvents');
                    }, 1500);
                } else {
                    commit('setError', response.data.message);
                    commit('setErrorStatus', response.status);
                }
            })
            .catch(function (error) {
                commit('setError',  'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus',  'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                output = false;
            }).then(function () {
                return output;
            });
    },

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    resetStore({ commit }) {
        commit('setEvents', []);
        commit('setEventsLoading', false);
        commit('setSelectedEvent', null);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
