var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar'),_c('Hero',{attrs:{"title":"Anomalias","message":"Os mais recentes jogos que não se inserem no schedule.","icon":"mdi-alarm-light","action":"withAction","layout_type":"anomalies"}}),_c('v-container',[_c('v-card',{staticStyle:{"margin-top":"-80px"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-tabs',{attrs:{"slot":"extension","background-color":"transparent","color":"accent"},slot:"extension",model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-row',{staticClass:"pt-5 mt-5"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":false,"return-value":_vm.menus.date.value,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.menus.date, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.menus.date, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.menus.date.value),callback:function ($$v) {_vm.$set(_vm.menus.date, "value", $$v)},expression:"menus.date.value"}},on))]}}]),model:{value:(_vm.menus.date.open),callback:function ($$v) {_vm.$set(_vm.menus.date, "open", $$v)},expression:"menus.date.open"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.menus.date.value),callback:function ($$v) {_vm.$set(_vm.menus.date, "value", $$v)},expression:"menus.date.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menus.date.open = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.dateHandler(_vm.menus.date.value)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.filter(),"search":_vm.dataTable.search,"items-per-page":_vm.dataTable.itemsPerPage,"footer-props":_vm.dataTable.footerProps,"loading":_vm.loading,"no-data-text":"Não foram encontrados resultados","no-results-text":"Não foram encontrados resultados","loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.name_networks",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/networks/' + item.id_groups}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',_vm._g({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.image_networks)}},on)),_vm._v(" "+_vm._s(item.name_networks)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name_networks)+" ")])])],1)]}},{key:"item.name_users",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/players/' + item.id_users}},[_vm._v(" "+_vm._s(item.name_users)+" ")])]}},{key:"item.name_groups",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/groups/' + item.id_groups}},[_vm._v(" "+_vm._s(item.name_groups)+" ")])]}},{key:"item.name_games",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.generateSharkScopeGameUrl(item.identifier_networks, item.id_ss_games),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-shark-fin")])],1),_vm._v(" "+_vm._s(item.name_games)+" ")]}},{key:"item.buyin_games",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol_currencies)+" "+_vm._s(_vm._.round(item.buyin_games, 2).toFixed(2))+" ")]}},{key:"item.rebuys_reentries",fn:function(ref){
var item = ref.item;
return [(_vm.calcNumRebuysReentries(item.rebuys_nhg, item.multi_entries_nhg) > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.calcNumRebuysReentries(item.rebuys_nhg, item.multi_entries_nhg))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.generateRebuysReeentriesString(item.rebuys_nhg, item.multi_entries_nhg)))])]):_vm._e()]}},{key:"item.position_nhg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.position_nhg)+" / "+_vm._s(item.total_entrants_games)+" ")]}},{key:"item.net_nhg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol_currencies)+" "+_vm._s(_vm._.round(item.net_nhg, 2).toFixed(2))+" "),_c('v-chip',{attrs:{"color":item.roi_nhg >= 0 ? 'green' : 'red',"dark":"","x-small":""}},[_vm._v(" "+_vm._s(_vm._.round(item.roi_nhg, 2).toFixed(2))+" % ")])]}},{key:"item.flag_analysis",fn:function(ref){
var item = ref.item;
return [(item.flag_analysis == 1)?[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Falso-positivo ")])]:(item.flag_analysis == 2)?[_c('v-chip',{attrs:{"color":"dark","dark":""}},[_vm._v(" Corrigido ")])]:(item.flag_analysis == 3)?[_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" Ignorado ")])]:[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Bem assinalado ")])]]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item.flag_analysis == 2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.flag(item)}}},on),[_vm._v(" mdi-flag-remove ")])]}}],null,true)},[(item.flag_analysis != 0)?_c('span',[_vm._v("Assinalar como falso-positivo")]):_c('span',[_vm._v("Desmarcar falso-positivo")])])]}}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1),(!_vm.loading && !_vm.anomalies)?_c('AlertNoData',{staticClass:"mt-5",attrs:{"gender":"a","asset":"anomalias"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }