import { mapGetters } from 'vuex';
import { provide } from 'vue';
import AuthMixin from '@/mixins/Auth';
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';
import PokerTablePlayers from '@/components/Tables/PokerTablePlayers/PokerTablePlayers.vue';

export default {
    name: 'DialogAddPlayerPokerTable',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        playersUp: {
            type: Array,
            required: true,
            default: () => []
        },
        playersDown: {
            type: Array,
            required: true,
            default: () => []
        },
        showComponent: {
            type: Boolean,
            required: false,
            default: false
        },
        direction: {
            type: String,
            required: false,
            default: null,
        },
        index: {
            type: Number,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: false,
            default: 'dialog',
        },
    },

    mixins: [
        AuthMixin,
    ],

    components: {
        DialogAddEditPlayerLive,
        PokerTablePlayers,
    },

    inject: ['resetPlayers'],
    
    data() {
        return this.initialState();
    },
    computed: {},
    watch: {
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
            }
        },
    },
    methods: {
        initialState() {
            return {
                submitting: false,
                dialogues: {
                    addEditPlayer: false,
                },
            }
        },
        closeDialog() {
            this.$root.$emit("clear-search");
            this.$emit("close-dialog");
        },

        showResetButton() {
            return this.playersUp.some(player => player.id) || this.playersDown.some(player => player.id);
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },

        localResetPlayers() {
            this.resetPlayers();
            this.$root.$emit('clear-players');
        },

        async submit() {
        }
    },
}