import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import Hero from '@/components/Hero/Hero.vue';
import TableLiveTournaments from '@/components/Tables/TableLiveTournaments/TableLiveTournaments.vue';
import DialogAddEditTournamentLive from '@/components/Dialog/DialogAddEditTournamentLive/DialogAddEditTournamentLive.vue';


export default {
    name: 'Tournaments-Single',

    components: {
        Hero,
        TableLiveTournaments,
        DialogAddEditTournamentLive,
    },

    mixins: [
        UserMixin,
        AuthMixin,
        DataMixin,
    ],

    async created() {
        if(this._.isNull(this.selectedTournament)) {
            await this.fetchData();
        }
    },

    computed: {
        ...mapGetters({
            tournaments: 'liveTournaments/tournaments',
            selectedTournament: 'liveTournaments/selectedTournament',
            selectedTournamentLoading: 'liveTournaments/selectedTournamentLoading',
            error: 'liveTournaments/error',
            humanError: 'liveTournaments/humanError',
        }),
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                dialogues: {
                    edit: false,
                }
            };
        },

        dialogEditTournament() {
            this.dialogues.edit = true;
        },

        async fetchData() {
            let payload = {
                 id: this.$route.params.id,
            }

            await this.$store.dispatch('liveTournaments/fetchTournamentById', payload)
        },   
    }
}