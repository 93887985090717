var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formAddEditPlayerLive",attrs:{"lazy-validation":""}},[_c('Snackbar'),_c('v-dialog',{attrs:{"value":_vm.isOpen,"width":"600","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"scrollable":""},on:{"input":function($event){return _vm.closeDialog()},"click:outside":function($event){return _vm.closeDialog()}}},[_c('v-card',{staticClass:"overflow-x-hidden"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogTitle())+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-n2",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('AlertError',{staticClass:"px-6",attrs:{"error":_vm.error,"human-error":_vm.humanError}}),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome do jogador","outlined":"","hide-details":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Biografia","outlined":"","hide-details":""},model:{value:(_vm.form.biography),callback:function ($$v) {_vm.$set(_vm.form, "biography", $$v)},expression:"form.biography"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"País","items":_vm.countries ? _vm.countries : [],"item-value":"name","item-text":"name","no-data-text":"País não encontrado","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"mr-7"},[_c('v-badge',{attrs:{"avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":require('@/assets/images/countries/' + _vm.getCodeFromCountry(item.name))}})],1)]},proxy:true}],null,true)})],1),_c('div',[_c('span',[_vm._v(_vm._s(item.name))])])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"mr-5"},[_c('v-badge',{attrs:{"avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":require('@/assets/images/countries/' + _vm.getCodeFromCountry(item.name))}})],1)]},proxy:true}],null,true)})],1),_c('div',[_c('span',[_vm._v(_vm._s(item.name))])])])]}}]),model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Link externo","append-icon":"mdi-link-variant","outlined":"","hide-details":""},model:{value:(_vm.form.external_link),callback:function ($$v) {_vm.$set(_vm.form, "external_link", $$v)},expression:"form.external_link"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.submitting,"color":"success"},on:{"click":function($event){return _vm.submit()}}},[(_vm.submitting)?_c('span',[_c('v-icon',[_vm._v("mdi-loading mdi-spin")])],1):_c('span',[_vm._v(" Adicionar ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }