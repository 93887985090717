import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    livePlayers: [],
    livePlayersLoading: false,
    selectedPlayer: null,
    selectedPlayerLoading: false,
    selectedPlayerNicknames: [],
    selectedPlayerTags: [],
    selectedPlayerTagsLoading: false,
    error: null,
    humanError: null,
    errorStatus: null,
    livePlayerById: [],
    livePlayerByIdLoading: false,
    selectedPlayerNotes: [],
    selectedPlayerNotesLoading: true,
    selectedPlayerNicknamesLoading: false,

    selectedPlayerNotesScroll: [],
    selectedPlayerNotesScrollLoading: true,

    search: [],
    searchLoading: false,
    searchError: null,
    searchHumanError: null,
};

const getters = {
    livePlayers: (state) => state.livePlayers,
    livePlayersLoading: (state) => state.livePlayersLoading,
    selectedPlayer: (state) => state.selectedPlayer,
    selectedPlayerLoading: (state) => state.selectedPlayerLoading,
    selectedPlayerNicknames: (state) => state.selectedPlayerNicknames,
    selectedPlayerTags: (state) => state.selectedPlayerTags,
    selectedPlayerTagsLoading: (state) => state.selectedPlayerTagsLoading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    errorStatus: (state) => state.errorStatus,
    livePlayerById: (state) => state.livePlayerById,
    livePlayerByIdLoading: (state) => state.livePlayerByIdLoading,
    selectedPlayerNotes: (state) => state.selectedPlayerNotes,
    selectedPlayerNotesLoading: (state) => state.selectedPlayerNotesLoading,
    selectedPlayerNicknamesLoading: (state) => state.selectedPlayerNicknamesLoading,

    selectedPlayerNotesScroll: (state) => state.selectedPlayerNotesScroll,
    selectedPlayerNotesScrollLoading: (state) => state.selectedPlayerNotesScrollLoading,
    
    search: (state) => state.search,
    searchLoading: (state) => state.searchLoading,
    searchError: (state) => state.searchError,
    searchHumanError: (state) => state.searchHumanError,
};

const mutations = {
    setLivePlayers: (state, payload) => { state.livePlayers = payload },
    setLivePlayersLoading: (state, payload) => { state.livePlayersLoading = payload },
    setSelectedPlayer: (state, payload) => { state.selectedPlayer = payload },
    setSelectedPlayerLoading: (state, payload) => { state.selectedPlayerLoading = payload },
    setSelectedPlayerNicknames: (state, payload) => { state.selectedPlayerNicknames = payload },
    setSelectedPlayerTags: (state, payload) => { state.selectedPlayerTags = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setLivePlayerById: (state, payload) => { state.livePlayerById = payload },
    setLivePlayerByIdLoading: (state, payload) => { state.livePlayerByIdLoading = payload },
    addLivePlayer: (state, payload) => {
        if (!state.livePlayers || !state.livePlayers.length)
            state.livePlayers = [];

        state.livePlayers.unshift(payload);
    },
    setSelectedPlayerNotes: (state, payload) => { state.selectedPlayerNotes = payload },
    setSelectedPlayerNotesLoading: (state, payload) => { state.selectedPlayerNotesLoading = payload },
    setSelectedPlayerTagsLoading: (state, payload) => { state.selectedPlayerTagsLoading = payload },
    setSelectedPlayerNicknamesLoading: (state, payload) => { state.selectedPlayerNicknamesLoading = payload },

    setSelectedPlayerNotesScroll: (state, payload) => { state.selectedPlayerNotesScroll = [...state.selectedPlayerNotesScroll, ...payload] },
    setSelectedPlayerNotesScrollLoading: (state, payload) => { state.selectedPlayerNotesScrollLoading = payload },
    resetSelectedPlayerNotesScroll: (state) => { state.selectedPlayerNotesScroll = []},

    setSearch: (state, payload) => { state.search = payload },
    setSearchLoading: (state, payload) => { state.searchLoading = payload },
    setSearchError: (state, payload) => { state.searchError = payload },
    setSearchHumanError: (state, payload) => { state.searchHumanError = payload },
};

const actions = {
    // Fetch the list of live players
    async fetchLivePlayers({ commit }, payload) {
        commit('setLivePlayersLoading', true);
        commit('setLivePlayers', []);
        commit('setError', null);

        let request_url = Vue.prototype.$url_api_live + 'players';

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setLivePlayers', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setLivePlayers', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setErrorStatus', response.status);
            output = false;
        })
        .then(function () {
            commit('setLivePlayersLoading', false);
            return output;
        });
    },

    // Fetch details of a selected player
    async fetchSelectedPlayer({ commit }, playerId) {
        commit('setSelectedPlayer', null);
        commit('setSelectedPlayerLoading', true);
        commit('setSelectedPlayerNicknames', []);
        commit('setSelectedPlayerTags', []);
        commit('setError', null);

        try {
            const response = await axios.get(Vue.prototype.$url_api_live + `players/${playerId}`);
            if (response.status === 200) {
                commit('setSelectedPlayer', response.data);
                commit('setSelectedPlayerNicknames', response.data.nicknames);
                commit('setSelectedPlayerTags', response.data.tags);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        } catch (error) {
            commit('setSelectedPlayerLoading', false);
            commit('setError', error.response ? error.response.data.message : error.message);
            commit('setErrorStatus', error.response ? error.response.status : null);
        }
        commit('setSelectedPlayerLoading', false);
    },

    // Fetch tag details of a selected player
    async fetchSelectedPlayerTags({ commit }, playerId) {
        commit('setSelectedPlayerTagsLoading', true);
        commit('setSelectedPlayerTags', []);

        try {
            const response = await axios.get(Vue.prototype.$url_api_live + `players/${playerId}`);
            if (response.status === 200) {
                commit('setSelectedPlayerTags', response.data.tags);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        } catch (error) {
            commit('setSelectedPlayerTagsLoading', false);
            commit('setError', error.response ? error.response.data.message : error.message);
            commit('setErrorStatus', error.response ? error.response.status : null);
        }
        commit('setSelectedPlayerTagsLoading', false);
    },

    // Fetch nickname details of a selected player
    async fetchSelectedPlayerNicknames({ commit }, playerId) {
        commit('setSelectedPlayerNicknamesLoading', true);
        commit('setSelectedPlayerNicknames', []);

        try {
            const response = await axios.get(Vue.prototype.$url_api_live + `players/${playerId}`);
            if (response.status === 200) {
                commit('setSelectedPlayerNicknames', response.data.nicknames);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        } catch (error) {
            commit('setSelectedPlayerNicknamesLoading', false);
            commit('setError', error.response ? error.response.data.message : error.message);
            commit('setErrorStatus', error.response ? error.response.status : null);
        }
        commit('setSelectedPlayerNicknamesLoading', false);
    },

    async fetchSelectedPlayerNotes({ commit, dispatch, state }, payload) {
        commit('setSelectedPlayerNotesLoading', true);
        commit('setSelectedPlayerNotes', []);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api_live + `players/${payload.params.id}/notes`;

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault()
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setSelectedPlayerNotes', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setSelectedPlayerNotes', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setErrorStatus', response.status);
            output = false;
        })
        .then(function () {
            commit('setSelectedPlayerNotesLoading', false);
            return output;
        });
    },
    
    async fetchSelectedPlayerNotesScroll({ commit, dispatch, state }, payload) {
        commit('setSelectedPlayerNotesScrollLoading', true);
        commit('setSelectedPlayerNotesScroll', []);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api_live + `players/${payload.params.id}/notes`;

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault()
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                if(payload && payload.params && payload.params.search){
                    commit('resetSelectedPlayerNotesScroll');
                }
                commit('setSelectedPlayerNotesScroll', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setSelectedPlayerNotesScroll', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            commit('setSelectedPlayerNotesScrollLoading', false);
            return output;
        });
    },

    // Add a nickname to a player
    async addPlayerNickname({ commit }, payload) {
        const userID = payload.id;

        // Handle payload data
        var data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'players/' + userID;

        var config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                if (response.status === 200) {
                    commit('setSelectedPlayerNicknames', data.nicknames);
                    output = true;
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    // Add a tag to a player
    async addPlayerTag({ commit }, payload ) {
        commit('setError', null);
        commit('setSelectedPlayerTagsLoading', true);
        commit('setSelectedPlayerTags', []);

        const playerId = payload.id;

        let data = payload;

        // Configure request_url
        const request_url = Vue.prototype.$url_api_live + 'players/' + playerId;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            if (response.status === 200) {
                output = true;
                commit('setSelectedPlayerTags', data.tags);
                commit('setSelectedPlayer', data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setSelectedPlayerTagsLoading', false);
            return output;
        });
    },

    // Delete a player
    async deletePlayer({ commit, dispatch }, playerId) {
        commit('setError', null);

        try {
            const response = await axios.delete(Vue.prototype.$url_api_live + `players/${playerId}`);
            if (response.status === 204) {
                // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                setTimeout(() => {
                    // refresh
                    dispatch('fetchLivePlayers');
                }, 1500);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        } catch (error) {
            commit('setError', error.response ? error.response.data.message : error.message);
            commit('setErrorStatus', error.response ? error.response.status : null);
        }
    },

    // Create a new player
    async createPlayer({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearErrors');

        // Handle payload data
        let data = payload;

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'players';

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (response.status === 201) {
                commit('addLivePlayer', response.data);
                commit('setSelectedPlayer', response.data);
                output = true;
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updatePlayer({ commit, dispatch }, payload) {
        const userID = payload.id;

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api_live + 'players/' + userID;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setSelectedPlayer', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async search({ commit, dispatch }, payload) {
        commit('setSearchLoading', true);
        // commit('setSearch', []);
        commit('setSearchError', null);

        let request_url = Vue.prototype.$url_api_live + 'search';

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setSearch', response.data);
            } else {
                commit('setSearchError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        }).catch(function (error) {
            commit('setSearchError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setErrorStatus', error.response.status);
            output = false;
        }).then(function () {
            commit('setSearchLoading', false);
            return output;
        });
    },

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    resetStore({ commit }) {
        commit('setLivePlayers', []);
        commit('setLivePlayersLoading', false);
        commit('setSelectedPlayer', null);
        commit('setSelectedPlayerNicknames', []);
        commit('setSelectedPlayerTags', []);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
        commit('setError', null);
        commit('resetSelectedPlayerNotesScroll');
        commit('setSelectedPlayerNotesScrollLoading', false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
