var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 pb-0"},[_c('v-form',{ref:"CardStepperNetwork",attrs:{"lazy-validation":""}},[(_vm.text)?_c('p',{staticClass:"body-2 mb-1"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c('v-row',{staticClass:"align-center pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('NicknameDropdown',{ref:"NicknameDropdown",attrs:{"label":"Sala","type":"PLAYER","scope":_vm.userIsRailManager ? 'ALL' : 'MINE',"user":_vm.userIsRailManager ? _vm.user : null,"excluded-networks":_vm.handleNetworksFilter(),"exclude-zero-balance":_vm.isSplit,"only-nicknames":!_vm.userIsRailManager},on:{"change":function (element) { return _vm.setActiveItem(element); }},model:{value:(_vm.form.wallet),callback:function ($$v) {_vm.$set(_vm.form, "wallet", $$v)},expression:"form.wallet"}})],1),_c('v-col',{attrs:{"cols":""}},[_c('input-financial',{ref:"inputFinancial",attrs:{"precision":_vm.handleDecimalNumbers(_vm.form.wallet),"vbind":{
              label: 'Montante',
              outlined: true,
              rules: _vm.rules.balance,
              appendIcon: _vm.getCurrencyIcon(),
              disabled: ! _vm.form.wallet,
            }},model:{value:(_vm.form.balance.value),callback:function ($$v) {_vm.$set(_vm.form.balance, "value", _vm._n($$v))},expression:"form.balance.value"}})],1),_c('v-btn',{staticClass:"mx-3 mb-7",attrs:{"disabled":_vm.form.wallet == null || _vm.form.balance.value == null || _vm.validateBalance(),"color":"success white--text","small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.addWalletToNicknamesList()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1)],1),_c('v-row',[_c('v-expand-transition',[(_vm.form.nicknames.length)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-divider'),_c('v-data-table',{staticClass:"table-nicknames",attrs:{"headers":_vm.headers,"items":_vm.form.nicknames,"item-key":"network","group-by":"currency.symbol","hide-default-footer":"","footer-props":{
            itemsPerPageOptions: [-1],
          },"disable-pagination":"","fixed-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
          var group = ref.group;
          var headers = ref.headers;
          var toggle = ref.toggle;
          var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" Moeda: "+_vm._s(group))])]),_c('v-col',{staticClass:"d-flex justify-end align-center"},[(group == '$')?_c('span',[_vm._v("Total: "+_vm._s(_vm.totalDollar)+" ")]):_vm._e(),(group == '€')?_c('span',[_vm._v("Total: "+_vm._s(_vm.totalEuro)+" ")]):_vm._e(),_c('v-btn',{ref:group,staticClass:"ml-4",attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1)],1)],1)]}},{key:"item.network",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.wallet.network.imageURL)}}),_c('span',[_vm._v(_vm._s(item.network))])])]}},{key:"item.balance",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(" "+_vm._s(item.currency.symbol)+" "+_vm._s(item.balance.value)+" ")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeFromNicknamesList(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,false,819213840),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }