var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar'),_c('Hero',{attrs:{"title":"Anomalias","message":"Os mais recentes jogos em que participou que não se inserem no schedule.","icon":"mdi-alarm-light","action":"withAction","layout_type":"anomalies"}}),_c('v-container',[(!_vm.data.length && _vm.dataFetchComplete)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"error","icon":"mdi-cloud-alert"}},[_c('h3',[_vm._v("Não foram encontradas anomalias")]),_c('span',[_vm._v("Não foram encontradas anomalias dentro dos critérios específicados. Por favor tente pesquisar por critérios distintos.")]),_c('v-divider',{staticClass:"my-4 error",staticStyle:{"opacity":"0.22"}}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Se acreditas que isto é um erro, por favor entra em contacto. ")]),_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"error","outlined":""}},[_vm._v(" Contactar ")])],1)],1)],1):_vm._e(),_c('v-card',{staticStyle:{"margin-top":"-80px"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pt-5 mt-5"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":false,"return-value":_vm.menus.date.value,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.menus.date, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.menus.date, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.menus.date.value),callback:function ($$v) {_vm.$set(_vm.menus.date, "value", $$v)},expression:"menus.date.value"}},on))]}}]),model:{value:(_vm.menus.date.open),callback:function ($$v) {_vm.$set(_vm.menus.date, "open", $$v)},expression:"menus.date.open"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.menus.date.value),callback:function ($$v) {_vm.$set(_vm.menus.date, "value", $$v)},expression:"menus.date.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menus.date.open = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.dateHandler(_vm.menus.date.value)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.anomalies,"search":_vm.dataTable.search,"items-per-page":10,"footer-props":_vm.dataTable.footerOptions,"loading":_vm.loading,"loading-text":"A carregar...","no-data-text":"Não foram encontrados resultados","no-results-text":"Não foram encontrados resultados"},scopedSlots:_vm._u([{key:"item.timestamp_analysis",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.timestamp_analysis).format('LL'))+" ")]}},{key:"item.flag_analysis",fn:function(ref){
var item = ref.item;
return [(item.flag_analysis == 1)?[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Falso-positivo ")])]:(item.flag_analysis == 2)?[_c('v-chip',{attrs:{"color":"dark","dark":""}},[_vm._v(" Corrigido ")])]:(item.flag_analysis == 3)?[_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" Ignorado ")])]:[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Bem assinalado ")])]]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item.flag_analysis == 2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.flag(item)}}},on),[_vm._v(" mdi-flag-remove ")])]}}],null,true)},[(item.flag_analysis != 0)?_c('span',[_vm._v("Desmarcar falso-positivo")]):_c('span',[_vm._v("Assinalar como falso-positivo")])])]}}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }