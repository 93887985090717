import { mapGetters } from 'vuex';
import TableNotes from "@/components/Tables/TableNotes/TableNotes.vue";
import DialogAddEditPlayerLive from "@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue";
import LivePlayerInformation from "@/components/LivePlayerInformation/LivePlayerInformation.vue";
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogAddNicknamesToPlayerLive from '@/components/Dialog/DialogAddNicknamesToPlayerLive/DialogAddNicknamesToPlayerLive.vue';

export default {
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: {
        TableNotes,
        DialogAddEditPlayerLive,
        LivePlayerInformation,
        EmptyStateNoData,
        DialogAddNicknamesToPlayerLive,
    },

    watch: {
        'tabs.current': async function(newValue, oldValue) {
           if(newValue !== oldValue){
                switch (this.tabs.headers[newValue]) {
                    case 'Notas':
                        this.$root.$emit('change-fab-button', 'notes');
                        break;
                    case 'Info':
                        this.$root.$emit('change-fab-button', 'info');
                        break;
                    case 'Nicknames':
                        this.$root.$emit('change-fab-button', 'nicknames');
                        await this.fetchNicknames(this.selectedPlayer.id);
                        break;
                
                    default:
                        break;
                }
            }
        },

        selectedPlayer: async function(newValue, oldValue){
            if(newValue) this.$root.$emit('reload-table-notes');
            if((newValue && newValue.id) && newValue !== oldValue){
                await this.fetchNicknames(newValue.id);
            }
        },
    },

    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
            selectedPlayerNicknames: 'livePlayers/selectedPlayerNicknames',
            selectedPlayerTags: 'livePlayers/selectedPlayerTags',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
        }),

        player: function () {
            return this.selectedPlayer;
        },

        filteredNicknames() {
            if (!this.search) {
                return this.selectedPlayerNicknames;
            }
            return this.selectedPlayerNicknames.filter(nickname => 
                nickname.name.toLowerCase().includes(this.search.toLowerCase()) ||
                (nickname.room && nickname.room.toLowerCase().includes(this.search.toLowerCase()))
            );
        },
    },

    async created(){
        if(!this._.isEmpty(this.selectedPlayer)){
            await this.$store.dispatch('livePlayers/fetchSelectedPlayer', this.selectedPlayer.id);
        }
    },

    data() {
        return {
            tabs: {
                current: 0,
                headers: ['Notas', 'Info', 'Nicknames'],
              },
            search: '',
            dialogues: {
                editPlayer: false,
                addNicknamesToPlayer: false,
            }
        }
    },

    methods: {
        hideComponent() {
            this.$emit("hide-component");
        },

        async fetchNicknames(id=null){
            if(id){
                await this.$store.dispatch('livePlayers/fetchSelectedPlayerNicknames', id);
            }
        },

        async fetchPlayer(id=null){
            if(id){
                await this.$store.dispatch('livePlayers/fetchSelectedPlayer', id);
            }
        },

        checkIsOpen(){
            return this.dialogues.editPlayer;
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        customStyle(){
            if(this.vue.breakdown.mdAndUp){
                return 'width: 55%';
            }
            return 'width: 55%';
        },

        removePlayer(){
            this.$root.$emit('remove-player', this.selectedPlayer);
            this.$root.$emit('clear-players', this.selectedPlayer);
            this.hideComponent();
        },
    }
}